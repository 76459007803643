import _ from 'lodash';
import md5 from 'md5';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { APP_IMG, SHOW_COMMEMORATE } from 'src/constants';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const SectionLandingPage = ({ data = [] }) => {
  if (_.isEmpty(data)) return null;

  const router = useRouter();
  const [visible, setVisible] = useState(false);

  function openCookieAccept() {
    const cookieFirstPage = Cookies.get('first_page');
    if (cookieFirstPage) {
      document.getElementById('landing-page')?.remove();
    }
    setVisible(!cookieFirstPage);
  }

  async function handleAccept() {
    const _id = md5('first_page');
    const _expires = 1 / 48;

    //! DATALAYER
    sendDataLayerGlobal({
      type: DATALAYER_TYPE.TRACK,
      router: router?.pathname,
      section: 'landing-page',
      data: {
        heading: 'เข้าสู่เว็บไซต์',
        title: data?.title
      }
    });

    Cookies.set('first_page', _id, { expires: _expires });
    router.reload(window.location.pathname);
  }

  useEffect(() => {
    openCookieAccept();
  }, [visible]);

  useEffect(() => {
    if (visible) {
      document.getElementById('__next')?.classList?.add('show-landing-page');
      document.getElementById('footer-landing')?.setAttribute('style', 'display:flex;');
      document.getElementById('truehits_div')?.setAttribute('style', 'display:none;');
    } else {
      document.getElementById('__next')?.classList?.remove('show-landing-page');
      document.getElementById('footer-landing')?.setAttribute('style', 'display:none;');
      document.getElementById('truehits_div')?.setAttribute('style', 'display:flex;');
    }
  }, [visible]);

  return (
    <>
      <LandingWrapper
        id='landing-page'
        className='landing-wrapper'
        dataImage={{ ...data }}
        onClick={() => handleAccept()}
        style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}
      >
        <div className='footer-landing'>
          <button id='btn-inside' aria-label='inside' title='inside'>
            <span> เข้าสู่เว็บไซต์ </span>
          </button>
        </div>
      </LandingWrapper>
    </>
  );
};

const LandingWrapper = styled.div`
  /* overflow: hidden; */
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999999999;
  background-image: ${({ dataImage }) => `url(${APP_IMG}/${dataImage?.imagePath})`};
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: rgba(256, 256, 256, 1);
  .footer-landing {
    position: absolute;
    bottom: 0;
    display: flex;
    height: 200px;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;
    /* background: rgba(256, 256, 256, 1);
    background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 50%); */
  }
  button {
    /* position: absolute;
    bottom: 10%;
    right: 5%; */
    /* right: 50%;
    transform: translate(50%, 0); */

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 40px;
    max-width: 200px;
    margin: 20px 30px 20px auto;
    padding: 5px 10px;
    cursor: pointer !important;
    background: #ffe000;
    /* background: linear-gradient(
      350deg,
      rgba(82, 32, 0, 1) 0%,
      rgba(147, 62, 5, 1) 25%,
      rgba(239, 128, 8, 1) 50%,
      rgba(147, 62, 5, 1) 75%,
      rgba(82, 32, 0, 1) 100%
    ); */
    color: #000;
    font-size: 18px;
    border: none;
    /* border: 2px solid #eeeeee;; */
    border-radius: 50px;
    box-shadow: 0px 2px 3px #c3c3c3;
    span {
      font-family: 'Prompt', sans-serif;
    }
  }
  @media (min-width: 1044px) {
    background-size: cover;
  }

  @media (min-width: 767px) and (max-width: 900px) {
    .footer-landing {
      height: 200px;
      /* background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 80%); */
    }
  }

  @media (max-width: 767px) {
    background-image: ${({ dataImage }) => `url(${APP_IMG}/${dataImage?.imagePathMobile})`};
    .footer-landing {
      justify-content: center;
      /* height: 100px; */
      /* background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 40%); */
    }
  }

  @media (max-width: 475px) {
    .footer-landing {
      /* background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 40%); */
    }
    button {
      /* bottom: 10%;
      right: 50%;
      transform: translate(50%, 0); */
      max-width: 150px;
      font-size: 16px;
      margin: 20px auto 20px auto;
    }
  }
`;

export default SectionLandingPage;
